import logo from './assets/cjjd-white-no-bg.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <main className='main'>
        <span className='logo'>
          <img id='Image' src={logo} alt='CJJD'  height={400}/>
        </span>
      </main>

      <footer className='footer'>
        CJJD.DIGITAL
      </footer>
    </div>
  );
}

export default App;
